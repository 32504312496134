import qs from 'qs'
import { createHash, randomBytes } from 'crypto'

export function generateVerifierCode() {
	const code = base64URLEncode(randomBytes(32))
	const hash = base64URLEncode(sha256(code))

	return { code, hash }
}

export function base64URLEncode(str) {
	return str
		.toString('base64')
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=/g, '')
}

export function sha256(buffer) {
	return createHash('sha256')
		.update(buffer)
		.digest()
}

export function encodeToQueryString(value) {
	return qs.stringify(value)
}

export function decodeFromQueryString(value) {
	return qs.parse(value, { ignoreQueryPrefix: true })
}
