import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, makeStyles, Divider, } from '@material-ui/core';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
}));


const Header = (props) => {
    const classes = useStyles();
    const [selectedClient, setSelectedClient] = useState(props.selectedClient)

    useEffect(() => {
        setSelectedClient(props.selectedClient)
    }, [props.selectedClient])

    return (
        <>
            <Grid container >
                <Grid item container
                    className={classes.title}
                >
                    <Grid item container xs={12}>
                        <Grid item container xs={6} >
                            <Grid item xs={5}>
                                <Typography variant="h5" >
                                    Werbesperre Service
                                </Typography>
                            </Grid>
                            <Grid item xs={3} container alignItems="center">
                                <Grid item >
                                    <Typography variant="h6" >
                                        {selectedClient.company_wsp === true ? <FormattedMessage id="companyWSPTitle" /> : <FormattedMessage id="personalWSPTitle" />}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} justify="flex-end">
                            <Grid item>
                                <Button
                                    disabled={props.enableSave()}
                                    onClick={() => props.submit()}
                                    color="primary"
                                    variant="contained"
                                >
                                    {<FormattedMessage id="buttonSaveText" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Agent: {props.loggedInUser}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider
                className={classes.divider}
            />
        </>
    )
}

export default Header