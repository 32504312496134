import React from 'react';

 const NotFound = () => {
    return (
        <div>
            Not Found!
        </div>
    )
}

export default NotFound