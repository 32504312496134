import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles, Card, CardContent, Button, } from '@material-ui/core';
import isEmptyObj from '../helper/isEmptyObj'
import HardEscalationDialog from './HardEscalationDialog'
import Warning from "./Warning";
import SaveChangesOverviewDialog from './SaveChangesOverviewDialog'
import SwitchUserDialog from './SwitchUserDialog'
import { FormattedMessage } from "react-intl";
import translationsDE from "../translations/de.json"

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    alert: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    card: {
        border: "1px solid #09a1e2",
        minHeight: 193,
        minWidth: 400,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#eff8fa",
            color: "#09a1e2",
        }
    },
    cardContent: {
        paddingLeft: theme.spacing(2)
    },
    formControl: {
        minWidth: 290,
        minHeight: 55,
        color: 'white',
        fontWeight: 'bold',
    },
    assistiveText: {
        width: 290,
        minWidth: 290,
        minHeight: 55,
        fontWeight: 'bold',
        marginTop: 10
    }
}));

const ClientInfo = (props) => {

    const classes = useStyles();
    const caseIdShort = props.caseIdShort
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    const [backgroundColorUser, setBackgroundColorUser] = useState(client.selected === true ? '#d8eef3' : '')
    const [backgroundColorCompany, setBackgroundColorCompany] = useState(debi.selected === true ? '#d8eef3' : '')
    const [open, setOpen] = useState(false)
    const [openSaveChanges, setOpenSaveChanges] = useState(false)
    const [warningHardEscalation, setWarningHardEscalation] = useState(false)
    const [warningNoUser, setWarningNoUser] = useState(false)
    const [dialogSwitchUser, setDialogSwitchUser] = useState(false)
    const selectedClient = client.selected ? client : debi

    const allChecked = {
        'allPrint': true,
        'bigCatalog': true,
        'email': true,
        'marketResearch': true,
        'print<50pages': true,
        'telephone': true
    }

    const allCheckedUpdates = {
        [translationsDE.email]: translationsDE[true],
        [translationsDE.allPrint]: translationsDE[true],
        [translationsDE.telephone]: translationsDE[true],
        [translationsDE.bigCatalog]: translationsDE[true],
        [translationsDE.marketResearch]: translationsDE[true],
        [translationsDE['print<50pages']]: translationsDE[true],
    }

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
    }, [props.client, props.debi])

    const selectPersonal = () => {
        setBackgroundColorUser('#d8eef3')
        setBackgroundColorCompany('')
        props.setClientSelection(false)
    }

    const selectCompany = () => {
        setBackgroundColorUser('')
        setBackgroundColorCompany('#d8eef3')
        props.setClientSelection(true)
    }

    const handleChangePersonal = () => {
        if (debi.hard_escalation !== true) {
            if (isEmptyObj(client))
                setWarningNoUser(true)
            else if (debi.updates !== undefined) {
                setDialogSwitchUser(true)
            }
            else selectPersonal()
        }
        else setWarningHardEscalation(true)
    };

    const handleChangeCompany = () => {
        if (debi.hard_escalation !== true) {
            if (!isEmptyObj(client)) {
                if (client.updates !== undefined) {
                    setDialogSwitchUser(true)
                }
                else selectCompany()
            }
        }
    }

    const handleAcceptHardEscalation = () => {
        setOpen(false)
        setWarningHardEscalation(false)
        props.updateDebi(
            {
                ...debi,
                hard_escalation: true,
                selected: true,
                groups: translationsDE.groupOptions.option1,
                all_brands: {
                    level: translationsDE.levelOptions.option4,
                    duration: translationsDE.durationOptions.option5,
                    ...allChecked
                },
                haufe_brand: {},
                akademie_brand: {},
                sp_brand: {},
                lexware_brand: {},
                updates:
                {
                    [translationsDE.all]: {
                        [translationsDE.duration]: translationsDE.durationOptions.option5,
                        [translationsDE.level]: translationsDE.levelOptions.option4,
                        ...allCheckedUpdates
                    },
                    [translationsDE.groups]: translationsDE.groupOptions.option1,
                }
            }
        )
        if (!isEmptyObj(client)) {
            setBackgroundColorUser('')
            setBackgroundColorCompany('#d8eef3')
            props.updateClient({
                ...client,
                selected: false
            })
        }
    };

    const handleRemoveHardEscalation = () => {

        const storedCompany = JSON.parse(localStorage.getItem('storedCompany'))
        setOpen(false)
        setWarningHardEscalation(false)

        if (JSON.stringify(storedCompany) !== JSON.stringify(debi)) {

            props.updateDebi({
                ...storedCompany,
                selected: true,
            })
        }
        else {
            props.updateDebi({
                ...debi,
                hard_escalation: false,
                groups: null,
                selected: true,
                all_brands: {},
                haufe_brand: {},
                akademie_brand: {},
                sp_brand: {},
                lexware_brand: {},
                updates:
                {
                    [translationsDE.all]: {},
                    [translationsDE.groups]: null,
                }
            })
        }
        props.updateClient({
            ...client,
            selected: false
        })
    }

    const closeHardEscalationDialog = () => {
        setOpen(false)
    }

    const handleHardEscalationButton = () => {
        setOpen(true)
    }

    const closeWarningHardEscalation = () => {
        setWarningHardEscalation(false)
    }

    const closeWarningNoUser = () => {
        setWarningNoUser(false)
    }

    const closeSaveChangesDialog = () => {
        setOpenSaveChanges(false)
    }

    const openSaveChangesDialog = () => {
        setOpenSaveChanges(true)
    }

    const acceptSaveChangesDialog = () => {
        closeSaveChangesDialog()
        props.enableOverviewClient()
        props.submit()
    }

    const closeDialogSwitchUser = () => {
        setDialogSwitchUser(false)
    }

    const switchUser = () => {
        closeDialogSwitchUser()
        if (client.selected) selectCompany()
        else selectPersonal()
    }

    const enableButton = () => {
        if (selectedClient.updates === undefined)
            return false
        else return props.enableSave()

    }

    const handleOverview = () => {
        if (selectedClient.updates)
            openSaveChangesDialog()
        else props.goToOverviewClient()
    }

    return (
        <>
            <Warning
                text={<FormattedMessage id="warningHardEscalationOn" />}
                warning={warningHardEscalation}
                closeWarning={closeWarningHardEscalation}
            />
            <Warning
                text={<FormattedMessage id="warningNoContactId" />}
                warning={warningNoUser}
                closeWarning={closeWarningNoUser}
            />

            <Grid container className={classes.container}>
                <Grid item container spacing={3}  >
                    <Grid item xs={4} >
                        <Card className={classes.card} style={{ background: backgroundColorUser }} onClick={handleChangePersonal}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6" gutterBottom>
                                    <FormattedMessage id="personalWSPTitle" />
                                </Typography>
                                <Typography variant="subtitle1">
                                    Name: {client.name !== undefined ? client.name : <FormattedMessage id="unknown" />}
                                    <br />
                                    <FormattedMessage id="personalWSPTitle" />-ID:  {client.sap_ansp_id || <FormattedMessage id="unknown" />}
                                    <br /><br />
                                    <FormattedMessage id="case" />-ID : {caseIdShort || <FormattedMessage id="unknown" />}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.card} style={{ background: backgroundColorCompany }} onClick={handleChangeCompany}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h6" gutterBottom>
                                    <FormattedMessage id="companyWSPTitle" />
                                </Typography>
                                <Typography variant="subtitle1">
                                    Name:  {debi.company_name || <FormattedMessage id="unknown" />}
                                    <br />
                                    <FormattedMessage id="companies" />-ID: {debi.sap_debi_id || <FormattedMessage id="unknown" />}
                                    <br /> <br />
                                    <FormattedMessage id="address" />:  {((debi.company_address_street === null ? '' : debi.company_address_street + ", ") +
                                        (debi.company_address_city === null ? '' : debi.company_address_city + ", ") +
                                        (debi.company_address_country === null ? '' : debi.company_address_country)) || <FormattedMessage id="unknown" />}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item container justify="flex-end" xs={3} style={{ paddingRight: 0 }}>
                        <Grid item >
                            <Button
                                className={classes.formControl}
                                color="primary"
                                variant="contained"
                                disabled={enableButton()}
                                onClick={handleOverview}>
                                <FormattedMessage id="overview" />
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button disabled={client.selected === true} style={{ background: debi.hard_escalation ? 'green' : 'red', marginTop: 20 }} onClick={handleHardEscalationButton} className={classes.formControl} variant="contained">
                                {debi.hard_escalation ? <FormattedMessage id="hardEscalationDeactivated" /> : <FormattedMessage id="hardEscalationActivated" />}
                            </Button>
                        </Grid>
                        <Grid item >
                            <Typography className={classes.assistiveText} color='error' variant="caption" display="block" gutterBottom>
                                <FormattedMessage id="messageHardEscalation" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <HardEscalationDialog
                        open={open}
                        debi={debi}
                        closeHardEscalationDialog={closeHardEscalationDialog}
                        handleAcceptHardEscalation={handleAcceptHardEscalation}
                        handleRemoveHardEscalation={handleRemoveHardEscalation} />
                    <SaveChangesOverviewDialog
                        openSaveChanges={openSaveChanges}
                        closeSaveChangesDialog={closeSaveChangesDialog}
                        acceptSaveChangesDialog={acceptSaveChangesDialog}
                    />
                    <SwitchUserDialog
                        dialogSwitchUser={dialogSwitchUser}
                        closeDialogSwitchUser={closeDialogSwitchUser}
                        switchUser={switchUser}
                    />
                </Grid>
            </Grid>
        </>

    )
}

export default ClientInfo