import translationsDE from "../translations/de.json"
import isEmptyObj from './isEmptyObj'

export default function translateInitialClient(debi) {

    return {
        ...(!isEmptyObj(debi.all_brands) && {
            [translationsDE.brandOptions.option1]: {
                [translationsDE.duration]: debi.all_brands.duration,
                ...(debi.all_brands.email === true) && { [translationsDE.email]: translationsDE.true },
                ...(debi.all_brands.allPrint === true) && { [translationsDE.allPrint]: translationsDE.true },
                ...(debi.all_brands.bigCatalog === true) && { [translationsDE.bigCatalog]: translationsDE.true },
                ...(debi.all_brands.telephone === true) && { [translationsDE.telephone]: translationsDE.true },
                ...(debi.all_brands['print<50pages'] === true) && { [translationsDE['print<50pages']]: translationsDE.true },
                ...(debi.all_brands.marketResearch === true) && { [translationsDE.marketResearch]: translationsDE.true },
                [translationsDE.level]: debi.all_brands.level,
                [translationsDE.department]: debi.all_brands.department
            }
        }),
        ...(!isEmptyObj(debi.haufe_brand) && {
            [translationsDE.brandOptions.option2]: {
                [translationsDE.duration]: debi.haufe_brand.duration,
                ...(debi.haufe_brand.email === true) && { [translationsDE.email]: translationsDE.true },
                ...(debi.haufe_brand.allPrint === true) && { [translationsDE.allPrint]: translationsDE.true },
                ...(debi.haufe_brand.bigCatalog === true) && { [translationsDE.bigCatalog]: translationsDE.true },
                ...(debi.haufe_brand.telephone === true) && { [translationsDE.telephone]: translationsDE.true },
                ...(debi.haufe_brand['print<50pages'] === true) && { [translationsDE['print<50pages']]: translationsDE.true },
                ...(debi.haufe_brand.marketResearch === true) && { [translationsDE.marketResearch]: translationsDE.true },
                [translationsDE.level]: debi.haufe_brand.level,
                [translationsDE.department]: debi.haufe_brand.department
            }
        }),
        ...(!isEmptyObj(debi.lexware_brand) && {
            [translationsDE.brandOptions.option3]: {
                [translationsDE.duration]: debi.lexware_brand.duration,
                ...(debi.lexware_brand.email === true) && { [translationsDE.email]: translationsDE.true },
                ...(debi.lexware_brand.allPrint === true) && { [translationsDE.allPrint]: translationsDE.true },
                ...(debi.lexware_brand.bigCatalog === true) && { [translationsDE.bigCatalog]: translationsDE.true },
                ...(debi.lexware_brand.telephone === true) && { [translationsDE.telephone]: translationsDE.true },
                ...(debi.lexware_brand['print<50pages'] === true) && { [translationsDE['print<50pages']]: translationsDE.true },
                ...(debi.lexware_brand.marketResearch === true) && { [translationsDE.marketResearch]: translationsDE.true },
                [translationsDE.level]: debi.lexware_brand.level,
                [translationsDE.department]: debi.lexware_brand.department
            }
        }),
        ...(!isEmptyObj(debi.akademie_brand) && {
            [translationsDE.brandOptions.option4]: {
                [translationsDE.duration]: debi.akademie_brand.duration,
                ...(debi.akademie_brand.email === true) && { [translationsDE.email]: translationsDE.true },
                ...(debi.akademie_brand.allPrint === true) && { [translationsDE.allPrint]: translationsDE.true },
                ...(debi.akademie_brand.bigCatalog === true) && { [translationsDE.bigCatalog]: translationsDE.true },
                ...(debi.akademie_brand.telephone === true) && { [translationsDE.telephone]: translationsDE.true },
                ...(debi.akademie_brand['print<50pages'] === true) && { [translationsDE['print<50pages']]: translationsDE.true },
                ...(debi.akademie_brand.marketResearch === true) && { [translationsDE.marketResearch]: translationsDE.true },
                [translationsDE.level]: debi.akademie_brand.level,
                [translationsDE.department]: debi.akademie_brand.department
            }
        }),
        ...(!isEmptyObj(debi.sp_brand) && {
            [translationsDE.brandOptions.option5]: {
                [translationsDE.duration]: debi.sp_brand.duration,
                ...(debi.sp_brand.email === true) && { [translationsDE.email]: translationsDE.true },
                ...(debi.sp_brand.allPrint === true) && { [translationsDE.allPrint]: translationsDE.true },
                ...(debi.sp_brand.bigCatalog === true) && { [translationsDE.bigCatalog]: translationsDE.true },
                ...(debi.sp_brand.telephone === true) && { [translationsDE.telephone]: translationsDE.true },
                ...(debi.sp_brand['print<50pages'] === true) && { [translationsDE['print<50pages']]: translationsDE.true },
                ...(debi.sp_brand.marketResearch === true) && { [translationsDE.marketResearch]: translationsDE.true },
                [translationsDE.level]: debi.sp_brand.level,
                [translationsDE.department]: debi.sp_brand.department
            }
        }),
        ...(!isEmptyObj(debi.all_brands) && !isEmptyObj(debi.haufe_brand) && !isEmptyObj(debi.lexware_brand) && !isEmptyObj(debi.akademie_brand) && !isEmptyObj(debi.sp_brand) && { [translationsDE.groups]: debi.groups })
    }
}