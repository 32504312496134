const translateBrand = (params) => {
    switch (params.toLowerCase()) {
        case 'alle':
            return 'all_brands'
        case 'haufe':
            return 'haufe_brand'
        case 'akademie':
            return 'akademie_brand'
        case 'lexware':
            return 'lexware_brand'
        case 'schäffer-poeschel':
            return 'sp_brand'
        default: return null
    }
}

export default translateBrand