import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import history from './helper/history'
import { IntlProvider } from "react-intl";
import locale_en from "./translations/en.json";
import locale_de from "./translations/de.json";

const data = {
    'de': locale_de,
    'en': locale_en
};
const searchParams = new URLSearchParams(history.location.search)

ReactDOM.render(
    <IntlProvider locale={searchParams.get('lang') || 'de'} messages={data[searchParams.get('lang') || 'de']}>
        <App />
    </IntlProvider>

    , document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
