import React, { useState, useEffect } from "react";
import { Grid, makeStyles, MenuItem, FormControl, InputLabel, Select, FormControlLabel, Switch } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import translateBrand from "../translations/translateBrand"
import translationsDE from "../translations/de.json"

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
    },
    formControl: {
        minWidth: 290,
        minHeight: 55,
    },
    link: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(20.5),
        position: 'absolute'
    },
    checkboxeGroups: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(-3)
    }
}));


const Channels = (props) => {
    const brandList = Object.values(translationsDE.brandOptions).map(value => value)
    const durationList = Object.values(translationsDE.durationOptions).map(value => value)

    const classes = useStyles();
    const [brand, setBrand] = useState('');
    const [duration, setDuration] = useState(translationsDE.durationOptions.option5);
    const [email, setEmail] = useState(false)
    const [allPrint, setAllPrint] = useState(false)
    const [bigCatalog, setBigCatalog] = useState(false)
    const [telephone, setTelephone] = useState(false)
    const [print, setPrint] = useState(false)
    const [marketResearch, setMarketResearch] = useState(false)
    const [allChannels, setAllChannels] = useState(false)
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    let selectedBrand = translateBrand(brand)
    const getSelectedClient = () => client.selected === true ? client : debi

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
    }, [props.client, props.debi])

    useEffect(() => {
        if (email === true && allPrint === true && bigCatalog === true && telephone === true && print === true && marketResearch === true)
            setAllChannels(true)
    }, [email, allPrint, bigCatalog, telephone, print, marketResearch])

    useEffect(() => {
        props.updateBrand(brand)
    }, [brand])

    useEffect(() => {
        setDefault()
    }, [client.selected])

    useEffect(() => {
        const hard_escalation = debi.hard_escalation

        if (hard_escalation === true) {
            setHardEscalation()
        } else {
            setDefault()
        }
    }, [debi.hard_escalation])

    const setHardEscalation = () => {
        setAllChannels(true)
        setBrand(translationsDE.brandOptions.option1)
        setDuration(translationsDE.durationOptions.option5)
        setToggles(true)
    }

    const setDefault = () => {
        setBrand('')
        setDuration(translationsDE.durationOptions.option5)
        setAllChannels(false)
        setToggles(false)
    }

    const setToggles = (value) => {
        setEmail(value)
        setAllPrint(value)
        setBigCatalog(value)
        setTelephone(value)
        setPrint(value)
        setMarketResearch(value)
    }

    const getUpdates = () => getSelectedClient().updates ? getSelectedClient().updates[brand] : {}


    const handleBrand = event => {
        const value = event.target.value
        let brand = translateBrand(value)
        let checkboxes = Object.entries(getSelectedClient()[brand])
            .map(([key, value]) => ({ key, value }))

        setBrand(value)
        setDuration(getSelectedClient()[brand].duration ? getSelectedClient()[brand].duration : 'Für immer')
        setAllChannels(false)
        setEmail(toggleFilterByLabel(checkboxes, 'email'))
        setAllPrint(toggleFilterByLabel(checkboxes, 'allPrint'))
        setBigCatalog(toggleFilterByLabel(checkboxes, 'bigCatalog'))
        setTelephone(toggleFilterByLabel(checkboxes, 'telephone'))
        setPrint(toggleFilterByLabel(checkboxes, 'print<50pages'))
        setMarketResearch(toggleFilterByLabel(checkboxes, 'marketResearch'))

    }

    const toggleFilterByLabel = (checkboxes, label) => {
        let toggle = checkboxes.filter(item => item.key === label)

        if (toggle[0] !== undefined)
            return toggle[0].value
        return false
    }

    const handleDuration = event => {
        const value = event.target.value
        let duration = value
        let update = getUpdates()

        setDuration(value)
        if (client.selected === true)
            props.updateClient({
                ...client,
                [selectedBrand]: { ...client[selectedBrand], duration },
                updates: { ...client.updates, [brand]: { ...update, [translationsDE.duration]: duration } }
            })
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: { ...debi[selectedBrand], duration },
                updates: { ...debi.updates, [brand]: { ...update, [translationsDE.duration]: duration } }
            })
    }

    const selectAllChannels = (item) => {
        return {
            'duration': duration,
            'email': item,
            "allPrint": item,
            "telephone": item,
            "bigCatalog": item,
            "marketResearch": item,
            "print<50pages": item,
        }
    }

    const selectAllChannelsUpdates = (item) => {
        return {
            [translationsDE.duration]: duration,
            [translationsDE.email]: translationsDE[item],
            [translationsDE.allPrint]: translationsDE[item],
            [translationsDE.telephone]: translationsDE[item],
            [translationsDE.bigCatalog]: translationsDE[item],
            [translationsDE.marketResearch]: translationsDE[item],
            [translationsDE['print<50pages']]: translationsDE[item],
        }
    }

    const updateClientToggle = (label, checked) => {
        let update = getUpdates()
        if (client.selected === true) {
            props.updateClient({
                ...client,
                [selectedBrand]: {
                    ...client[selectedBrand],
                    duration: duration,
                    [label]: checked
                },
                updates: {
                    ...client.updates,
                    [brand]: {
                        ...update,
                        [translationsDE.duration]: duration,
                        [translationsDE[label]]: translationsDE[checked]
                    }
                }
            })
        }
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    duration: duration,
                    [label]: checked
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...update,
                        [translationsDE.duration]: duration,
                        [translationsDE[label]]: translationsDE[checked]
                    }
                }
            })
    }

    const handleSelectAllChannels = () => {

        setAllChannels(!allChannels)
        setToggles(!allChannels)
        setDuration(!allChannels ? duration : translationsDE.durationOptions.option5)
        if (client.selected === true)
            props.updateClient({
                ...client,
                [selectedBrand]: {
                    ...client[selectedBrand],
                    ...selectAllChannels(!allChannels)
                },
                updates: {
                    ...client.updates,
                    [brand]: {
                        ...(client.updates && client.updates[brand]),
                        ...selectAllChannelsUpdates(!allChannels)
                    }
                }
            })
        else if (debi.selected === true)
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    ...selectAllChannels(!allChannels)
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...(debi.updates && debi.updates[brand]),
                        ...selectAllChannelsUpdates(!allChannels)
                    }
                }
            })
    }

    const onChangeToggleEmail = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        if (getSelectedClient().selected) {
            setEmail(checked)
            updateClientToggle('email', checked)
        }
    }

    const onChangeToggleAllPrint = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setAllPrint(checked)
        updateClientToggle('allPrint', checked)
    }

    const onChangeToggleBigCatalog = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setBigCatalog(checked)
        updateClientToggle('bigCatalog', checked)
    }

    const onChangeToggleTelephone = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        if (getSelectedClient().selected) {
            setTelephone(checked)
            updateClientToggle('telephone', checked)
        }
    }

    const onChangeTogglePrint = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setPrint(checked)
        updateClientToggle('print<50pages', checked)
    }

    const onChangeToggleMarketResearch = (event) => {
        let checked = event.target.checked

        if (allChannels === true)
            setAllChannels(false)
        setMarketResearch(checked)
        updateClientToggle('marketResearch', checked)
    }

    return (
        <Grid container className={classes.container}>
            <Grid item container spacing={2}>
                <Grid item >
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel><FormattedMessage id="brand" /></InputLabel>
                        <Select
                            disabled={debi.hard_escalation}
                            value={brand || ''}
                            onChange={handleBrand}
                            label="Brand"
                        >
                            {brandList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel><FormattedMessage id="duration" /> (Optional)</InputLabel>
                        <Select
                            disabled={debi.hard_escalation || brand === ''}
                            className={classes.selectEmpty}
                            value={duration || translationsDE.durationOptions.option5}
                            onChange={handleDuration}
                            label="Duration"
                        >
                            {durationList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
            <Grid item container className={classes.checkboxeGroups} spacing={4}>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={email}
                                onChange={onChangeToggleEmail}
                                color="primary"
                            />
                        }
                        label='E-Mail'
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={allPrint}
                                onChange={onChangeToggleAllPrint}
                                color="primary"
                            />
                        }
                        label={<FormattedMessage id="allPrint" />}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={bigCatalog}
                                onChange={onChangeToggleBigCatalog}
                                color="primary"
                            />
                        }
                        label={<FormattedMessage id="bigCatalog" />}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item container xs={3}>
                    <Grid item >
                        <FormControlLabel
                            value="top"
                            control={<Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={allChannels}
                                name="allChannels"
                                color="primary"
                                onChange={handleSelectAllChannels}
                            />}
                            label={<FormattedMessage id="allChannels" />}
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={telephone}
                                onChange={onChangeToggleTelephone}
                                color="primary"
                            />
                        }
                        label={<FormattedMessage id="telephone" />}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={print}
                                onChange={onChangeTogglePrint}
                                color="primary"
                            />
                        }
                        label={<FormattedMessage id="print<50pages" />}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        className={classes.checkbox}
                        value="top"
                        control={
                            <Switch
                                disabled={debi.hard_escalation || brand.length === 0}
                                checked={marketResearch}
                                onChange={onChangeToggleMarketResearch}
                                color="primary"
                            />
                        }
                        label={<FormattedMessage id="marketResearch" />}
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
        </Grid >
    )

}

export default Channels