import { AuthStore } from './AuthStore'
import { AuthError } from './AuthError'
import {
	generateVerifierCode,
	encodeToQueryString,
	decodeFromQueryString,
} from './AuthUtils'
import { cognitoClientID, cognitoBaseURL } from '../services/constants'

const BASE_URL = `${window.location.protocol}//${window.location.host}`

export class Auth {
	static getClientId() {
		return cognitoClientID
	}

	static config = {
		AUTHORIZE_URL: `${cognitoBaseURL}/oauth2/authorize`,
		TOKEN_URL: `${cognitoBaseURL}/oauth2/token`,
		LOGOUT_URL: `${cognitoBaseURL}/logout`,
		CLIENT_ID: Auth.getClientId(),
		LOGIN_REDIRECT_URI: `${BASE_URL}/auth/assert`,
		LOGOUT_REDIRECT_URI: `${BASE_URL}/auth/logout`,
		RESPONSE_TYPE: 'code',
		CODE_CHALLENGE_METHOD: 'S256', // This is the only hash method supported by AWS Cognito
	}

	static isLoggedIn() {
		return AuthStore.tokens && AuthStore.expiration > Date.now()
	}

	static async getToken() {
		if (!AuthStore.tokens) {
			return null
		}

		if (AuthStore.expiration < Date.now()) {
			return Auth.refreshToken()
		}

		return AuthStore.tokens.id_token
	}

	static async initiateLogout(returnUrl = null) {
		const params = {
			client_id: Auth.config.CLIENT_ID,
			logout_uri: Auth.config.LOGOUT_REDIRECT_URI,
		}

		const serializedParams = encodeToQueryString(params)
		const deAuthorizeUrl = Auth.config.LOGOUT_URL
		const querySeparator = deAuthorizeUrl.indexOf('?') < 0 ? '?' : '&'
		const logoutUrl = `${deAuthorizeUrl}${querySeparator}${serializedParams}`

		return logoutUrl
	}

	static async finalizeLogout(query) {
		const redirectUrl = AuthStore.redirectTo

		AuthStore.redirectTo = null
		AuthStore.codeVerifier = null
		AuthStore.tokens = null
		AuthStore.expiration = null

		return redirectUrl
	}

	static async initiateLogin(returnUrl, state = null) {
		const { code, hash } = generateVerifierCode()

		const params = {
			client_id: Auth.config.CLIENT_ID,
			redirect_uri: Auth.config.LOGIN_REDIRECT_URI,
			response_type: Auth.config.RESPONSE_TYPE,
			state: state ? JSON.stringify(state) : undefined,
			code_challenge_method: Auth.config.CODE_CHALLENGE_METHOD,
			code_challenge: hash,
		}

		const serializedParams = encodeToQueryString(params)
		const authorizeUrl = Auth.config.AUTHORIZE_URL
		const querySeparator = authorizeUrl.indexOf('?') < 0 ? '?' : '&'
		const loginUrl = `${authorizeUrl}${querySeparator}${serializedParams}`

		AuthStore.redirectTo = returnUrl || '/'
		AuthStore.codeVerifier = code
		AuthStore.tokens = null
		AuthStore.expiration = null

		return loginUrl
	}

	static async finalizeLogin(query) {
		const { code } = decodeFromQueryString(query)
		await Auth.getTokenWithCode(code)

		return AuthStore.redirectTo
	}

	static async refreshToken() {
		const params = {
			grant_type: 'refresh_token',
			client_id: Auth.config.CLIENT_ID,
			refresh_token: AuthStore.tokens.refresh_token,
		}

		return Auth.requestToken(params)
	}

	static async getTokenWithCode(authorizationCode) {
		const params = {
			grant_type: 'authorization_code',
			client_id: Auth.config.CLIENT_ID,
			redirect_uri: Auth.config.LOGIN_REDIRECT_URI,
			code: authorizationCode,
			code_verifier: AuthStore.codeVerifier,
		}

		return Auth.requestToken(params)
	}

	static async requestToken(payload) {
		const requestHeaders = new Headers({
			'Content-Type': 'application/x-www-form-urlencoded',
		})

		const requestInit = {
			method: 'POST',
			headers: requestHeaders,
			body: encodeToQueryString(payload),
		}

		const response = await fetch(Auth.config.TOKEN_URL, requestInit)
		const tokens = await response.json()

		if (tokens.error) {
			throw new AuthError(tokens.error)
		}

		Auth.setTokens(tokens)

		return Auth.getToken()
	}

	static setTokens(tokens) {
		AuthStore.tokens = tokens
		AuthStore.expiration = Date.now() + tokens.expires_in * 1000
	}

	static getDecodedToken(token) {
		var base64Url = token.split('.')[1]
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function(c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join('')
		)

		return JSON.parse(jsonPayload)
	}
}
