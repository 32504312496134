import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert'
import { Collapse, IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    warning: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    }
}));

const Warning = (props) => {
    const classes = useStyles();

    return (
        <Collapse className={classes.warning} in={props.warning}>
            <Alert
                severity="warning"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={props.closeWarning}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {props.text}
            </Alert>
        </Collapse>
    )
}

export default Warning