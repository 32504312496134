import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { FormattedMessage } from "react-intl";

const HardEscalationDialog = (props) => {
    const hard_escalation = props.debi.hard_escalation

    return (
        <Dialog open={props.open}>
            <DialogContent >
                <DialogContentText >
                    {hard_escalation ? <FormattedMessage id="hardEscalationDialogDeactivate" /> : <FormattedMessage id="hardEscalationDialogActivate" />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.closeHardEscalationDialog} >
                    <FormattedMessage id="deny" />
                </Button>
                <Button variant="contained" color="primary" onClick={!hard_escalation ? props.handleAcceptHardEscalation : props.handleRemoveHardEscalation} autoFocus>
                    <FormattedMessage id="confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default HardEscalationDialog