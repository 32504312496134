import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@material-ui/core';
import { FormattedMessage } from "react-intl";

const SwitchUserDialog = (props) => {

    return (
        <Dialog open={props.dialogSwitchUser}>
            <Typography variant='inherit' color='error' >
                <DialogTitle >
                    <FormattedMessage id="warning" />
                </DialogTitle>
            </Typography>
            <Divider />
            <DialogContent >
                <DialogContentText component={'span'}>
                    <Typography variant="h6" color='error' >
                        <FormattedMessage id="switchUserDialog" />
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" color="primary" onClick={props.switchUser} >
                    <FormattedMessage id="switch" />
                </Button>
                <Button variant="contained" onClick={props.closeDialogSwitchUser} autoFocus>
                    <FormattedMessage id="buttonCancelText" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SwitchUserDialog