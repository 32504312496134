import React from 'react';
import { createMuiTheme, Container } from '@material-ui/core'
import { responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Client from "./components/Client";
import NotFound from './components/NotFound'
import { Router, Route, Switch } from 'react-router-dom'
import { Auth } from "./auth";
import Axios from "axios";
import OverviewDialog from './components/OverviewDialog';
import history from './helper/history';


let theme = createMuiTheme({

  spacing: factor => `${1 * factor}rem`,
  palette: {
    primary: {
      light: '#2684ff',
      main: '#0747a6',
      dark: '#344563',
    },
    secondary: {
      light: '#C2185B',
      main: '#344563',
      dark: '#032b66',
      contrastText: '#344563',
    },
    text: {
      secondary: '#757575',
      disabled: '#9e9e9e',
      hint: '#9e9e9e',
      print: '#191919'
    },
  },
})

theme = responsiveFontSizes(theme)
const styles = {
  constainer: {
    minHeight: 950,
    minWidth: 1450,
  },
}

const App = () => {
  return (
    <ThemeProvider theme={theme} >
      <Container style={{ ...styles.constainer }} >
        <Router history={history}>
          <Switch>
            <Route path={`/login`} component={AuthLogin} />
            <Route
              path={`/auth/assert`}
              component={AuthLoginCallback}
            />
            <Route path={`/logout`} component={AuthLogout} />
            <Route
              path={`/auth/logout`}
              component={AuthLogoutCallback}
            />
            {!Auth.isLoggedIn() && (
              <Route path={`/`} component={AuthLogin} />
            )}
            <Route component={LayoutRouter} />
          </Switch>
        </Router>
      </Container>

    </ThemeProvider>

  )
}

class LayoutRouter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      loggedInUser: {}
    }
  }

  componentDidMount() {
    this.getToken()

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoggedIn !== this.state.isLoggedIn) {
      this.getToken()
    }
  }

  getToken() {
    Auth.getToken().then(token => {
      Axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      }
      const decodedToken = Auth.getDecodedToken(token)
      this.setState({
        isLoggedIn: true,
        loggedInUser: decodedToken.given_name + ' ' + decodedToken.family_name
      })
    })
  }


  render() {
    if (!this.state.isLoggedIn) {
      return null
    }
    return (
      <Router history={history}>
        <Switch>
          <Route path="/clients" >
            <Client user={this.state.loggedInUser} />
          </Route>
          <Route path="/overview" >
            <OverviewDialog />
          </Route>
          <Route >
            <NotFound />
          </Route>
        </Switch>
      </Router>
    );
  }
}

function AuthLogin(props) {
  let path = props.location.pathname + props.location.search
  if (path.endsWith('/login')) {
    path = '/'
  }
  Auth.initiateLogin(path).then(loginUrl => {
    window.location.href = loginUrl
  })

  return null
}

function AuthLoginCallback(props) {

  Auth.finalizeLogin(props.location.search)
    .catch(err => {
      const returnUrl = err.details ? err.details.returnUrl : '/'

      return Auth.initiateLogout(returnUrl)
    })
    .then(redirectUrl => {
      window.location.href = redirectUrl || '/'
    })

  return null
}

function AuthLogout(props) {
  Auth.initiateLogout('/').then(logoutUrl => {
    window.location.href = logoutUrl
  })

  return null
}

function AuthLogoutCallback(props) {
  Auth.finalizeLogout('/').then(redirectUrl => {
    window.location.href = redirectUrl
  })

  return null
}

export default App;
