import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import history from './../helper/history'
import isEmptyObj from '../helper/isEmptyObj'
import { ClientsService } from './../services/Clients'
import { FormattedMessage } from "react-intl";
import translationsDE from "../translations/de.json"

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1.5),
        backgroundColor: '#E0E0E0',
        margin: theme.spacing(1.5)
    },
    paperNoWSP: {
        padding: theme.spacing(1.5),
        backgroundColor: '#f2f2f2',
        margin: theme.spacing(1.5)
    },
    typography: {
        fontWeight: 'bold'
    },
    typographyNoWSP: {
        fontWeight: 'bold',
        color: '#cccccc'
    }
}));

const OverviewDialog = (props) => {
    const classes = useStyles();
    const accountId = new URLSearchParams(history.location.search).get('accountId')
    const contactId = new URLSearchParams(history.location.search).get('contactId')
    const [selectedClient, setSelectedClient] = useState({})

    const translateBrands = (params) => {
        switch (params) {
            case 'all_brands':
                return translationsDE.brandOptions.option1
            case 'haufe_brand':
                return translationsDE.brandOptions.option2
            case 'lexware_brand':
                return translationsDE.brandOptions.option3
            case 'akademie_brand':
                return translationsDE.brandOptions.option4
            case 'sp_brand':
                return translationsDE.brandOptions.option5
            default: return null
        }
    }

    const translateChannels = (params) => {
        switch (params) {
            case 'email':
                return translationsDE.email
            case 'telephone':
                return translationsDE.telephone
            case 'allPrint':
                return translationsDE.allPrint
            case 'bigCatalog':
                return translationsDE.bigCatalog
            case 'print<50pages':
                return translationsDE['print<50pages']
            case 'marketResearch':
                return translationsDE.marketResearch
            default: return null
        }
    }

    useEffect(() => {
        const fetchClient = async () => {
            const result = accountId !== null ? await ClientsService.getDebiBySFId(accountId) :
                await ClientsService.getAnspBySFId(contactId)
            setSelectedClient(result)
        }
        fetchClient()
    }, [])

    const brands = Object.entries(selectedClient)
        .map(([key, value]) => [key, value])
        .filter((item) => item[0].includes('brand') && !item[0].includes('last_update_all_brands'))

    const channels = (items) => {
        return Object.entries(items)
            .map(([key, value]) => [key, value])
            .filter((key) => key[0] !== translationsDE.duration && key[1] === true)
            .map((item) => translateChannels(item[0]))
    }

    if (!isEmptyObj(selectedClient))
        return (
            <Dialog maxWidth='lg' fullWidth open={true}>
                <DialogTitle >
                    <FormattedMessage id="overview" /> {selectedClient.company_wsp === false ? <FormattedMessage id="personalWSPTitle" /> : <FormattedMessage id="companyWSPTitle" />}
                </DialogTitle>
                <DialogContent dividers>
                    {brands.map((item, index) =>
                        <Paper key={index} elevation={0} square className={channels(item[1]).length !== 0 ? classes.paper : classes.paperNoWSP}>
                            <Grid item container spacing={1} direction="row">
                                <Grid item xs={2} >
                                    <Grid >
                                        <Typography variant="h6" className={channels(item[1]).length !== 0 ? classes.typography : classes.typographyNoWSP}>
                                            {translateBrands(item[0])}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {channels(item[1]).length !== 0 ?
                                    <>
                                        <Grid item container direction='column' xs={2} >
                                            <Grid item>
                                                <Typography variant="overline" >
                                                    <FormattedMessage id="level" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.typography}>
                                                    {selectedClient[item[0]].level || <FormattedMessage id="unknown" />}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='column' xs={3} >
                                            <Grid item >
                                                <Typography variant="overline" >
                                                    <FormattedMessage id="department" />
                                                </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant='body2' className={classes.typography}>
                                                    {(selectedClient[item[0]].department && selectedClient[item[0]].department.join(', ')) || <FormattedMessage id="unknown" />}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='column' xs={2}  >
                                            <Grid item>
                                                <Typography variant="overline" >
                                                    <FormattedMessage id="groups" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.typography}>
                                                    {selectedClient.groups || <FormattedMessage id="unknown" />}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='column' xs={2} >
                                            <Grid item>
                                                <Typography variant="overline" >
                                                    <FormattedMessage id="channels" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.typography}>
                                                    {channels(item[1]).join(', ')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction='column' xs={1} >
                                            <Grid item>
                                                <Typography variant="overline" >
                                                    <FormattedMessage id="duration" />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.typography}>
                                                    {item[1].duration || <FormattedMessage id="unknown" />}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </> :
                                    <Grid item container xs={9} justify="center" >
                                        <Grid item >
                                            <Typography variant='h6' className={classes.typographyNoWSP}>
                                                <FormattedMessage id="noWSP" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                    )}
                </DialogContent>
                {history.location.key !== undefined ? <DialogActions>
                    <Button
                        color="primary" variant="contained"
                        onClick={() => history.goBack()}>
                        <FormattedMessage id="goBack" />
                    </Button>
                </DialogActions> : null}
            </Dialog>
        )
    else return null
}

export default OverviewDialog


