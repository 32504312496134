import axios from "axios";
import { ENDPOINTS } from "./../services/constants";

class Clients {

    updateClient = async (data) => {
        return axios.put(ENDPOINTS.clients + `/${data.id}`, data)
            .then(response => response)
            .catch(err => console.error(err))
    }

    createClient = async (data) => {
        return axios.post(ENDPOINTS.clients, data)
            .then(response => response)
            .catch(err => console.error(err))
    };

    getAnspBySFId = async (id) => {
        return axios.get(ENDPOINTS.clients + `/contacts/SFId=${id}`)
            .then(res => res.data)
            .catch(err => console.error(err))
    }

    getDebiBySFId = async (id) => {
        return axios.get(ENDPOINTS.clients + `/accounts/SFId=${id}`)
            .then(res => res.data)
            .catch(err => console.error(err))
    }

    getShortCaseId = async (id) => {
        return axios.get(ENDPOINTS.clients + `/caseId=${id}`)
            .then(res => res.data)
            .catch(err => console.error(err))
    }

}

export const ClientsService = new Clients()