import React, { useState, useEffect } from "react";
import { Grid, Typography, makeStyles, MenuItem, FormControl, InputLabel, Select, Dialog, DialogTitle, Button, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import translateBrand from "../translations/translateBrand"
import translationsDE from "../translations/de.json"

const useStyles = makeStyles({
    container: {
        marginTop: '3rem',
        marginLeft: '3rem'
    },
    formControl: {
        minWidth: 290,
        minHeight: 55,
        color: 'white',
        fontWeight: 'bold'
    },
});


const ContactPerson = (props) => {
    const levelList = Object.values(translationsDE.levelOptions).map(value => value)
    const groupList = Object.values(translationsDE.groupOptions).map(value => value)
    const departmentList = Object.values(translationsDE.departmentOptions).map(value => value)
    let selectedBrand = translateBrand(props.brand)
    const classes = useStyles();
    const [client, setClient] = useState(props.client)
    const [debi, setDebi] = useState(props.debi)
    const getSelectedClient = () => client.selected === true ? client : debi
    const [level, setLevel] = useState(getSelectedClient() === debi ? (debi[selectedBrand] && debi[selectedBrand].level) : '');
    const [groups, setGroups] = useState(getSelectedClient().groups);
    const [department, setDepartment] = useState(getSelectedClient() === debi ? (debi[selectedBrand] && debi[selectedBrand].department) : []);
    const [open, setOpen] = useState(false)
    const [brand, setBrand] = useState(props.brand)
    const [oldLevel, setOldLevel] = useState(null)

    useEffect(() => {
        setClient(props.client)
        setDebi(props.debi)
        setBrand(props.brand)
    }, [props.client, props.debi, props.brand])

    useEffect(() => {
        setGroups(client.selected === true && debi.hard_escalation !== true ? client.groups : debi.groups)
        setLevel('')
        setDepartment([])
    }, [client.selected, debi.hard_escalation])


    useEffect(() => {
        setLevel(selectedBrand !== null ? getSelectedClient()[selectedBrand].level : '')
        setDepartment(selectedBrand !== null ? getSelectedClient()[selectedBrand].department : [])
    }, [selectedBrand])


    const handleLevel = (event) => {
        let newLevel = event.target.value
        setOldLevel(level)
        setLevel(newLevel)
        if (level === 'Account und alle Kontakte gesperrt' && newLevel !== 'Account und alle Kontakte gesperrt') {
            setOpen(true)
        } else {
            props.updateDebi({
                ...debi,
                [selectedBrand]: {
                    ...debi[selectedBrand],
                    level: newLevel,
                    // department: newLevel !== department ? [] : department,
                    ...(newLevel !== department) && { department: department }
                },
                updates: {
                    ...debi.updates,
                    [brand]: {
                        ...(debi.updates && debi.updates[brand]),
                        [translationsDE.level]: newLevel,
                        // [translationsDE.department]: newLevel !== department ? [] : department,
                        ...(newLevel !== department) && { [translationsDE.department]: department }
                    },
                }
            })
        }
        newLevel !== translationsDE.department && setDepartment([])
    }

    const handleCloseAndChange = () => {
        setOpen(false);
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                level: level
            },
            updates: {
                ...debi.updates,
                [brand]: {
                    ...(debi.updates && debi.updates[brand]),
                    [translationsDE.level]: level
                }
            }
        })
    };

    const handleClose = () => {
        setOpen(false);
        setLevel(oldLevel)
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                level: oldLevel
            },
            updates: {
                ...debi.updates
            }
        })
    }

    const handleGroups = event => {
        let groups = event.target.value
        setGroups(groups);
        if (debi.selected === true)
            props.updateDebi({
                ...debi,
                groups,
                updates: {
                    ...debi.updates,
                    [translationsDE.groups]: groups
                }
            })
        else if (client.selected === true)
            props.updateClient({
                ...client,
                groups,
                updates: {
                    ...client.updates,
                    [translationsDE.groups]: groups
                }
            })
    }

    const handleDepartment = event => {
        let department = event.target.value
        setDepartment(department);
        props.updateDebi({
            ...debi,
            [selectedBrand]: {
                ...debi[selectedBrand],
                department
            },
            updates: {
                ...debi.updates,
                [brand]: {
                    ...(debi.updates && debi.updates[brand]),
                    [translationsDE.department]: department
                }
            }
        })
    }

    let enableGroup = true
    if (debi.hard_escalation)
        enableGroup = true
    else if (debi.selected)
        if (debi.updates !== undefined || JSON.stringify(debi).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else if (client.selected)
        if (client.updates !== undefined || JSON.stringify(client).includes("duration"))
            enableGroup = false
        else enableGroup = true
    else enableGroup = false



    return (
        <Grid container className={classes.container}>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle >
                    <Typography color='error'>
                        Warnung !!!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component={'span'}>
                        <Typography color='error'>
                            Wenn Sie die Ebene wechseln, dann geht die aktuelle Werbesperre für die ganze 'Account und alle Kontakte gesperrt' verloren und
                            die Werbesperre gilt nur noch für einen Teil der Firma.Sind Sie sich sicher?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Nein, abbrechen
                    </Button>
                    <Button onClick={handleCloseAndChange} color="primary" variant="contained" autoFocus>
                        Ja, ändern
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h6" gutterBottom>
                <FormattedMessage id="appliesTo" />
            </Typography>
            <Grid item container spacing={2}>
                <Grid item >
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel><FormattedMessage id="level" /></InputLabel>
                        <Select
                            disabled={
                                client.selected ||
                                debi.hard_escalation ||
                                ((level === undefined || level === '') && debi.updates === undefined)
                            }
                            value={level || ''}
                            onChange={handleLevel}
                            label="Level"
                        >
                            {levelList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item >
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel><FormattedMessage id="department" /></InputLabel>
                        <Select
                            disabled={((level === translationsDE.levelOptions.option1) && (debi.selected) ? false : true) || debi.hard_escalation}
                            value={department || []}
                            onChange={handleDepartment}
                            label="Department"
                            multiple
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {departmentList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item >
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel><FormattedMessage id="groups" /> (Optional)</InputLabel>
                        <Select
                            disabled={enableGroup}
                            value={groups || ''}
                            onChange={handleGroups}
                            label="Groups"
                        >
                            <MenuItem value={null}>
                                <em>Keine Auswahl</em>
                            </MenuItem>
                            {groupList.map((name, index) =>
                                <MenuItem value={name} key={index.toString()}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContactPerson