import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Grid, Button } from '@material-ui/core';
import history from './../helper/history'
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(20),
    },
}));

const CircularIndeterminate = (props) => {
    const classes = useStyles();
    const [timer, setTimer] = useState(false)
    const time = 25000

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setTimer(true)
        }, time);
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center" spacing={1}>
                <>
                    {timer === true ?
                        <>
                            <Grid item>
                                <Typography color="error">{props.message}</Typography>
                            </Grid>
                            <Grid item >
                                <Button onClick={() => history.go(0)} variant="contained" > {'⟳ Neu laden'}</Button>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item>
                                <Typography color="primary"> <FormattedMessage id="loadingMsg" /></Typography>
                            </Grid>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </>
                    }
                </>
            </Grid>
        </div>
    );
}

export default CircularIndeterminate