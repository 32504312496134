import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Grid, Card, CardContent, makeStyles, Typography, DialogTitle, Divider } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
    category: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    card: {
        minHeight: 190,
        minWidth: 150,
    },
}))

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },

}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ConfirmationDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);
    const client = props.client
    const debi = props.debi

    const getSelectedClient = () => client.selected === true ? client : debi


    let updatedChannels = Object.entries(getSelectedClient().updates || {})
        .map(([key, value]) => ({ key, value }))
        .filter(brand => !brand.key.includes('Besonderheiten'))

    let groupsOption = Object.entries(getSelectedClient().updates || {})
        .filter(item => item[0].includes('Besonderheiten'))

    let updatedClientType = Object.entries(getSelectedClient().updates || {})
        .map(([key, value]) => ({ key, value }))
        .filter(item => item.key.includes('company_wsp'))

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleConfirm = () => {
        props.handleConfirm()
    };

    const handleClose = () => {
        props.handleClose()
    };

    const getToday = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //As January is 0.
        let yyyy = today.getFullYear();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return (dd + '-' + mm + '-' + yyyy)
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}>
                <Grid container alignItems="center" spacing={4}>
                    <Grid item>
                        <DialogTitle>
                            <FormattedMessage id="dialogConfirmationTitle" />
                        </DialogTitle>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Agent: {props.loggedInUser}
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <Card className={classes.card} style={{ background: client.selected !== true ? '' : '#d8eef3' }}>
                                <CardContent >
                                    <Typography style={{ fontWeight: 'bold' }} variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="personalWSPTitle" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Name: {client.first_name || <FormattedMessage id="unknown" />}
                                        <br />
                                        <FormattedMessage id="personalWSPTitle" />-ID:  {client.sap_ansp_id || <FormattedMessage id="unknown" />}
                                        <br /><br />
                                        <FormattedMessage id="case" />-ID : {client.case_id || <FormattedMessage id="unknown" />}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={classes.card} style={{ background: debi.selected === true ? '#d8eef3' : '' }}>
                                <CardContent >
                                    <Typography style={{ fontWeight: 'bold' }} variant="subtitle2" gutterBottom>
                                        <FormattedMessage id="companyWSPTitle" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Name:  {debi.company_name || <FormattedMessage id="unknown" />}
                                        <br />
                                        <FormattedMessage id="companies" />-ID: {debi.sap_debi_id || <FormattedMessage id="unknown" />}
                                        <br /><br />
                                        <FormattedMessage id="address" />:  {((debi.company_address_street === null ? '' : debi.company_address_street + ", ") +
                                            (debi.company_address_city === null ? '' : debi.company_address_city + ", ") +
                                            (debi.company_address_country === null ? '' : debi.company_address_country)) || <FormattedMessage id="unknown" />}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography className={classes.category} variant="subtitle2" gutterBottom>
                            <FormattedMessage id="channels" />
                        </Typography>
                        {updatedChannels.length > 0 ? updatedChannels.map(brand => (
                            <div key={brand.key}>
                                <Typography variant="subtitle2" gutterBottom>
                                    <FormattedMessage id="brand" />: {brand.key}
                                    <br />
                                    <FormattedMessage id="validFrom" />: {getToday()}
                                    <br />
                                    <FormattedMessage id="duration" />: {brand.value.Dauer}
                                    <br />
                                    <FormattedMessage id="channels" />: {
                                        Object.entries(brand.value)
                                            .map(item => item)
                                            .filter(item => (item[0] !== 'Dauer' && item[0] !== 'Ebene' && item[0] !== 'Themenbereich') ? item : '')
                                            .map(([key, value]) => key + ":" + value)
                                            .join(', ')
                                    }
                                    <br />
                                    <FormattedMessage id="level" />: {brand.value.Ebene}
                                    <br />
                                    <FormattedMessage id="department" />: {brand.value.Themenbereich&&Object.values(brand.value.Themenbereich).map(item => item).join(', ')}
                                </Typography>
                                {brand !== updatedChannels[updatedChannels.length - 1] && < Divider />}
                            </div>
                        )) : <Typography color='error'> <FormattedMessage id="noChannelsChanges" /></Typography>}
                        <Typography variant="subtitle2" className={classes.category} gutterBottom>
                            <FormattedMessage id="appliesTo" />
                        </Typography>
                        {groupsOption.length > 0 ? <Typography variant="subtitle2" gutterBottom>
                            <FormattedMessage id="groups" />: {groupsOption.length > 0 ? groupsOption[0][1] : ''}
                            <br />
                            <FormattedMessage id="temper" />: {debi.hard_escalation ? "Harte Eskalation" : "Normal"}
                        </Typography> : <Typography color='error'><FormattedMessage id="noContactChanges" /></Typography>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={updatedChannels.length === 0
                        && groupsOption.length === 0
                        && updatedClientType.length === 0 ? true : false} autoFocus onClick={handleConfirm} color="primary">
                        <FormattedMessage id="confirmDialogOverview" />
                    </Button>
                    <Button variant="contained" autoFocus onClick={handleClose} >
                        <FormattedMessage id="buttonCancelText" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmationDialog