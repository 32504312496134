export class AuthStore {
	static PREFIX = 'AUTH.'

	static prefix(key) {
		return AuthStore.PREFIX + key
	}

	static __get(key) {
		return JSON.parse(localStorage.getItem(AuthStore.prefix(key)))
	}

	static __set(key, value) {
		const keyName = AuthStore.prefix(key)
		if (value === null) {
			localStorage.removeItem(keyName)
		} else {
			localStorage.setItem(keyName, JSON.stringify(value))
		}
	}

	static get tokens() { return AuthStore.__get('tokens') }
	static set tokens(value) { AuthStore.__set('tokens', value) }

	static get expiration() { return AuthStore.__get('expiration') }
	static set expiration(value) { AuthStore.__set('expiration', value) }

	static get codeVerifier() { return AuthStore.__get('codeVerifier') }
	static set codeVerifier(value) { AuthStore.__set('codeVerifier', value) }

	static get redirectTo() { return AuthStore.__get('redirectTo') }
	static set redirectTo(value) { AuthStore.__set('redirectTo', value) }
}
