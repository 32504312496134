import { AuthStore } from './AuthStore'

export class AuthError extends Error {
	details = null

	constructor(message, details = null) {
		super(message)

		this.details = {
			returnUrl: AuthStore.redirectTo,
			...details,
		}
	}
}
